import React from "react"
import Helmet from "react-helmet"
import Layout from "../components/layout"
import { Container, Row, Col } from "react-bootstrap"
import ContactForm from "../components/contact-us-form"

export default () => (
	<Layout>
		<Helmet title="Unexpected error" defer={false} />

		<Container className="mt-3">
			<Row>
				<Col md="4">
					<ContactForm />
				</Col>
				<Col>
					<h1>Opps!</h1>
					<p>We weren't able to find what you were looking for.</p>
				</Col>
			</Row>

		</Container>
	</Layout>
)